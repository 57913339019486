<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Complaintsandsuggestions" style="width:100vw">
  <div id="a13c11ab">
    历史记录
  </div>
  <div id="a8b9c64a">
    <div id="ab2e7327">
      <div id="acb74848">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="bea1e903">
        已处理
      </div>
      <el-select id="a37c88ec" v-model="val_a37c88ec">
        <el-option v-for="item in op_a37c88ec" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <img src="../assets/img-cd5cda0c.png" id="cd5cda0c"/>
    <div id="a06e6490">
      <div id="aa67c9a1">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="a1e30869">
        处理中
      </div>
      <el-select id="f8f79bb5" v-model="val_f8f79bb5">
        <el-option v-for="item in op_f8f79bb5" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <img src="../assets/img-abcae2ba.png" id="abcae2ba"/>
    <div id="ad4190de">
      <div id="a005aef2">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="ae4b16d5">
        处理中
      </div>
      <el-select id="ebd0c6ff" v-model="val_ebd0c6ff">
        <el-option v-for="item in op_ebd0c6ff" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <img src="../assets/img-a28be581.png" id="a28be581"/>
    <div id="a5a8178a">
      <div id="a742b9da">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="a86c9d4f">
        已处理
      </div>
      <el-select id="ace663d6" v-model="val_ace663d6">
        <el-option v-for="item in op_ace663d6" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <img src="../assets/img-abcb6991.png" id="abcb6991"/>
    <div id="a3393101">
      <div id="e2241e0e">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="a88ccfc3">
        已处理
      </div>
      <el-select id="a5561adc" v-model="val_a5561adc">
        <el-option v-for="item in op_a5561adc" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <img src="../assets/img-daa5936d.png" id="daa5936d"/>
    <div id="aeb5c4b6">
      <div id="a662fae7">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="ad059376">
        处理中
      </div>
      <el-select id="a39ea87d" v-model="val_a39ea87d">
        <el-option v-for="item in op_a39ea87d" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <img src="../assets/img-a11e0e52.png" id="a11e0e52"/>
    <div id="adab9b9a">
      <div id="bc8b948b">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="af344217">
        已处理
      </div>
      <el-select id="ad37d556" v-model="val_ad37d556">
        <el-option v-for="item in op_ad37d556" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <img src="../assets/img-ae5fa59f.png" id="ae5fa59f"/>
    <div id="abd493f7">
      <div id="e882406d">
        个人建议个人建议个人建议个人建议个人建议个人建议个人建议。
      </div>
      <div id="aa1cc32c">
        处理中
      </div>
      <el-select id="da015b34" v-model="val_da015b34">
        <el-option v-for="item in op_da015b34" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div>
  <el-pagination id="a88632ab">
  </el-pagination>
  <div id="c597facb" ref="ref_c597facb">
  </div>
  <el-button id="a58168e8" @click="clicka58168e8()">
    提交
  </el-button>
  <div id="ae7c5142">
    <dal-compfloat id="cmpae7c5142">
    </dal-compfloat>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
import dal_editor from "wangeditor"
import dal_rest from "@/api/restful.js"
let edt_ref_c597facb = null
export default{
  components:{
    'dal-compfloat':dal_compfloat
  },
  data(){
    return{
    op_a37c88ec:[],
    val_a37c88ec:'',
    op_f8f79bb5:[],
    val_f8f79bb5:'',
    op_ebd0c6ff:[],
    val_ebd0c6ff:'',
    op_ace663d6:[],
    val_ace663d6:'',
    op_a5561adc:[],
    val_a5561adc:'',
    op_a39ea87d:[],
    val_a39ea87d:'',
    op_ad37d556:[],
    val_ad37d556:'',
    op_da015b34:[],
    val_da015b34:'',
    ref_c597facb:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
    edt_ref_c597facb = new dal_editor(this.$refs.ref_c597facb)
    edt_ref_c597facb.config.uploadImgMaxLength = 1
    edt_ref_c597facb.config.zIndex = 1
    edt_ref_c597facb.config.customUploadImg = (resultFiles, insertImgFn) => {
    let fd = new FormData();
            fd.append("file", resultFiles[0]);
            dal_rest.post("", fd, {
                headers: { "Content-Type": "multipart/form-data" },
                baseURL: "/myupload",
            })
            .then(
                (res) => {
                console.log("上传成功", res);
                let imgurl = res.content.url;
                insertImgFn(imgurl)
                },
                (err) => {
                console.log("上传失败", err);
                this.$message("上传失败");
                }
            )
        }
    edt_ref_c597facb.create()
    edt_ref_c597facb.txt.html(this.$route.query.articleContent)
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clicka58168e8(){
    }
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a13c11ab{
  padding-top:100px;
}
#a13c11ab{
  text-align:left;
  color:#141620;
  font-size:45px;
  font-family:PingFang SC;
}
#a8b9c64a{
  width:1323px;
  position:relative;
  margin-top:60px;
  margin-left:163px;
}
#a8b9c64a{
  background-color:rgb(252,252,255,1);
  border-radius:10px 10px 10px 10px;
  box-shadow:4px 4px 4px rgba(0,0,0,0.16);
}
#ab2e7327{
  width:1173px;
  position:relative;
  padding-left:75px;
  padding-top:50px;
}
#acb74848{
  display:inline-block;
  vertical-align: middle;
}
#acb74848{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#bea1e903{
  display:inline-block;
  vertical-align: middle;
}
#bea1e903{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#ab2e7327 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#cd5cda0c{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#cd5cda0c{
  vertical-align:middle;
}
#a06e6490{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#aa67c9a1{
  display:inline-block;
  vertical-align: middle;
}
#aa67c9a1{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a1e30869{
  display:inline-block;
  vertical-align: middle;
}
#a1e30869{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#a06e6490 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#abcae2ba{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#abcae2ba{
  vertical-align:middle;
}
#ad4190de{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a005aef2{
  display:inline-block;
  vertical-align: middle;
}
#a005aef2{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ae4b16d5{
  display:inline-block;
  vertical-align: middle;
}
#ae4b16d5{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#ad4190de .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a28be581{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#a28be581{
  vertical-align:middle;
}
#a5a8178a{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a742b9da{
  display:inline-block;
  vertical-align: middle;
}
#a742b9da{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a86c9d4f{
  display:inline-block;
  vertical-align: middle;
}
#a86c9d4f{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#a5a8178a .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#abcb6991{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#abcb6991{
  vertical-align:middle;
}
#a3393101{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#e2241e0e{
  display:inline-block;
  vertical-align: middle;
}
#e2241e0e{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a88ccfc3{
  display:inline-block;
  vertical-align: middle;
}
#a88ccfc3{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#a3393101 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#daa5936d{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#daa5936d{
  vertical-align:middle;
}
#aeb5c4b6{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#a662fae7{
  display:inline-block;
  vertical-align: middle;
}
#a662fae7{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ad059376{
  display:inline-block;
  vertical-align: middle;
}
#ad059376{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#aeb5c4b6 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#a11e0e52{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#a11e0e52{
  vertical-align:middle;
}
#adab9b9a{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
}
#bc8b948b{
  display:inline-block;
  vertical-align: middle;
}
#bc8b948b{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#af344217{
  display:inline-block;
  vertical-align: middle;
}
#af344217{
  text-align:left;
  color:#141620;
  font-size:30px;
  font-family:PingFang SC;
}
#adab9b9a .el-select{
  width:37px;
  margin-left:67px;
  padding-top:8px;
  padding-bottom:11px;
}
#ae5fa59f{
  width:1219px;
  height:3px;
  margin-top:39px;
  padding-left:52px;
}
#ae5fa59f{
  vertical-align:middle;
}
#abd493f7{
  width:1173px;
  position:relative;
  margin-top:39px;
  padding-left:75px;
  padding-bottom:50px;
}
#e882406d{
  display:inline-block;
  vertical-align: middle;
}
#e882406d{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#aa1cc32c{
  display:inline-block;
  vertical-align: middle;
}
#aa1cc32c{
  text-align:left;
  color:#c9171f;
  font-size:30px;
  font-family:PingFang SC;
}
#abd493f7 .el-select{
  width:37px;
  margin-left:67px;
  padding-top:7px;
  padding-bottom:12px;
}
#a88632ab{
  width:302px;
  margin-top:60px;
  padding-left:1184px;
}
#c597facb{
  width:1323px;
  margin-top:60px;
  padding-left:163px;
}
#a58168e8{
  width:115px;
  margin-top:49px;
  margin-left:1300px;
  margin-bottom:436px;
}
#a58168e8{
  background-color:rgb(20,22,32,1);
  border-radius:6px 6px 6px 6px;
}
#a58168e8{
  color:#fff;
  font-size:25px;
  font-family:PingFang SC;
  border:transparent;
}
#ae7c5142{
  width:150px;
  position:fixed;
  z-index: 50;
  top:1278px;
  left:1740px;
}
#ae7c5142{
  background-color:rgb(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
</style>
